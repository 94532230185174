<!--
 * @Author: jiang
 * @Date: 2021-06-13 11:20:40
 * @Description:
-->
<template>
  <div class="c-media-list">
    <div class="c-media-item" v-for="item in list" :key="item.id">
      <el-image class="icon" :src="handlerImageUrl(item.file_url)"></el-image>
      <div class="text g-text-ellipsis">{{ item.original_filename ||item.file_name}}</div>
      <div class="ext">.{{ item.extension }}</div>

      <div class="btn-box">
       <div  class="btn" target="_blank"  @click="(e) => handleEditFile(e, item)">
          <i  class="el-icon-edit"></i>预览编辑
        </div>
     <!-- <div  class="btn" target="_blank"  >
          <a :href="item.file_url||item.url" target="_blank" ><i  class="el-icon-view"></i>预览</a>
        </div> -->
        <div  class="btn" target="_blank"  @click="(e) => handleDownFile(e, item)"><i
            class="el-icon-download"></i>下载</div>
            <div  class="btn" target="_blank"  @click="(e) => handledelFile(e, item)"><i
              class="el-icon-download"></i>删除</div>


      </div>
    </div>
  </div>
</template>

<script>
 import {
    deleteHuizong
  } from '@/api/special/index'
  export default {
    props: {
      list: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      handleEditFile(e,item){
          	let url = this.$router.resolve({
          		path: '/manager/office',
          		query:  Object.assign(item)
          	})

          	window.open(url.href, '_blank')
      },
      downloadFile(url, filename) {

        this.$message({
                   message: '即将下载,请稍后...',
                   type: 'success'
                 });

          fetch(url, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/octet-stream'
              }
          }).then(response => response.blob()).then(blob => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
          }).catch(error => {
              prompt("自动下载失败，您的浏览器功能受限！需手动复制下面的完整网址，粘贴到浏览器新窗口地址栏进行下载：",url);
              return
          });
      },

      handleDownFile(e,item) {

         if (item.file_url) {
           this.download(item.file_url, item.original_filename + '.' + item.extension);
         }else {
           this.download(item.url, item.original_filename + '.' + item.extension);
         }

         e.stopPropagation();
      },
      handledelFile(e,item){
        var that = this;
        deleteHuizong(item.id)
              .then(res => {
                this.$emit('deleteFile');
              })
              .finally(() => {
                
              })
      },
      getBlob(url) {
        return new Promise(resolve => {
            const xhr = new XMLHttpRequest();

            xhr.open('GET', url, true);
            xhr.responseType = 'blob';
            xhr.crossOrigin = "*";
            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                }
            };

            xhr.send();
        });
    },
      saveAs(blob, filename) {
          if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, filename);
          } else {
              const link = document.createElement('a');
              const body = document.querySelector('body');

              link.href = window.URL.createObjectURL(blob);
              link.download = filename;

              // fix Firefox
              link.style.display = 'none';
              body.appendChild(link);

              link.click();
              body.removeChild(link);

              window.URL.revokeObjectURL(link.href);
          }
      },
      download(url, filename) {
          this.getBlob(url).then(blob => {
              this.saveAs(blob, filename);
          });
      },
      handlerImageUrl(ext) {
        console.log()
        const extMap = {
          jpg: 'img',
          gif: 'img',
          png: 'img',
          jpeg: 'img',
          csv: 'excel',
          xls: 'excel',
          xlsx: 'excel',
          doc: 'word',
          docx: 'word',
          pdf: 'ppt',
          ppt: 'ppt',
          txt: 'word',
          text: 'word',
          bmp: 'midea',
          mp4: 'midea',
          mp3: 'midea',
          wav: 'midea',
        }
        const icon = extMap[ext.split('.').pop()] || 'icon'
        return `/images/${icon}.png`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .c-media-list {
    display: flex;
    flex-wrap: wrap;

    .c-media-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 5px 8px;
      overflow: hidden;
      font-size: 14px;
      color: #2b2d42;
      background-color: #fff;
      border-radius: 3px;

  min-width: 200px;
      .icon {
        width: 26px;
        height: 26px;
        margin-right: 5px;
      }

      .text {
        max-width: 200px;
      }

      .btn-box {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000, $alpha: 0.4);
        opacity: 0;
        .btn {
          padding: 5px;
          margin-right: 5px;
          font-size: 12px;
          line-height: 1;
          color: #3db8db;
          background-color: #fff;
          border-radius: 5px;
        }
      }

      &:hover {
        .btn-box {
          opacity: 1;
        }
      }
    }
  }
</style>
